<template>
  <div style="padding-top: 50px">
    <div
      class="BPCheaderIcon"
      :style="
        `background-image: url(${logoTheme() || '/favicon-primary.png'});`
      "
    ></div>
    <BAServices />
  </div>
</template>

<script>
import BAServices from "./BA_Services_core";
import { mapGetters } from "vuex";

export default {
  components:{
    BAServices
  },
  computed: {
    ...mapGetters(["logoTheme"]),
  }
};
</script>